
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "../../../lib/util";
import AbpBase from "../../../lib/abpbase";
import PageRequest from "../../../store/entities/page-request";
import EditCampo from "./edit-campo.vue";
import PeopleFind from "../../../components/People/people-find.vue";

class PageCampoRequest extends PageRequest {
  keyword: string;
  from: Date;
  to: Date;
}

@Component({
  components: {
    EditCampo,
    PeopleFind,
  },
})
export default class Campo extends AbpBase {
  //filters
  pagerequest: PageCampoRequest = new PageCampoRequest();
  creationTime: Date[] = [];

  createModalShow: boolean = false;
  editModalShow: boolean = false;
  get list() {
    return this.$store.state.campo.list;
  }
  get loading() {
    return this.$store.state.campo.loading;
  }
  create() {
    this.createModalShow = true;
    this.$router.push({ name: "editcampo" });
  }
  editRow(item, index, button) {
    this.$store.commit("campo/edit", item);
    this.edit();
    this.$router.push({ name: "editcampo", params: { id: item.id } });
  }
  deleteRow(item, index, button) {
    this.$bvModal
      .msgBoxConfirm(this.L("¿Desea eliminar este campo?"), {
        //title: this.L('Tips'),
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: this.L("Sí"),
        cancelTitle: this.L("No"),
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value) {
          await this.$store.dispatch({
            type: "campo/delete",
            data: item,
          });
          await this.getpage();
        }
      });
  }
  edit() {
    this.editModalShow = true;
  }

  pageChange(page: number) {
    this.$store.commit("campo/setCurrentPage", page);
    this.getpage();
  }
  pagesizeChange(pagesize: number) {
    this.$store.commit("campo/setPageSize", pagesize);
    this.getpage();
  }
  async getpage() {
    this.pagerequest.maxResultCount = this.pageSize;
    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;
    //filters

    // if (this.creationTime.length > 0) {
    //   this.pagerequest.from = this.creationTime[0];
    // }
    // if (this.creationTime.length > 1) {
    //   this.pagerequest.to = this.creationTime[1];
    // }

    await this.$store.dispatch({
      type: "campo/getAll",
      data: this.pagerequest,
    });

    const pagination = { ...this.pagination };
    pagination.total = this.$store.state.campo.totalCount;
    pagination.pageSize = this.$store.state.campo.pageSize;
    pagination.current = this.$store.state.campo.currentPage;
    
    this.pagination = pagination;
  }
  get pageSize() {
    return this.$store.state.campo.pageSize;
  }
  get totalCount() {
    return this.$store.state.campo.totalCount;
  }
  get currentPage() {
    return this.$store.state.campo.currentPage;
  }
  set currentPage(page) {
    this.$store.commit("campo/setCurrentPage", page);
    this.getpage();
  }
  columns = [
    {
      title: this.L("Nombre"),
      dataIndex: "nombre",
      thClass: "w-auto",
      tdClass: "w-auto",
    },
    {
      title: this.L("Tipo"),
      dataIndex: "tipo",
      scopedSlots: { customRender: "status" },
    },
    {
      title: this.L("Reglas"),
      scopedSlots: { customRender: "rules" },
    },
    {
      title: this.L("ID"),
      dataIndex: "id",
      
    },
    {
      scopedSlots: { customRender: "actions" },
      title: "Acciones",
      fixed: "right",
    },
  ];

  getCampoValue(value) {
    if (
      this.$store.state.campo.listPropertyType.find((x) => x.value == value) !==
      undefined
    ) {
      return this.$store.state.campo.listPropertyType.find(
        (x) => x.value == value
      ).key;
    }
    
  }

  async created() {
    this.getpage();
    await this.$store.dispatch({
      type: "campo/getAllProperties",
      data: this.pagerequest,
    });
  }

  pagination: any = {};
  findModalShow: boolean = false;

  searchDefinition: {}[] = [
    {
      fieldName: "Nombre",
      fieldType: "text",
      fieldBind: "keyword",
      value: "",
    },
  ];

  getRules(r) {
    var obj = JSON.parse(r, (key, value) => {
      return {
          key: key,
          value: value
      }
    });
    console.log(obj);
  }

  handleTableChange = async (pagination, filters, sorter) => {
    this.$store.commit("campo/setCurrentPage", pagination.current);
    //this.getpage();

    await this.getpage();
  };

  async hide() {
    this.findModalShow = false;
  }
  async find() {
    this.findModalShow = true;
  }

  getSearch = async (model) => {
    model.forEach((element) => {
      if (element.value) {
        this.pagerequest[element.fieldBind] = element.value;
      } else {
        delete this.pagerequest[element.fieldBind];
      }
    });

    //this.pagerequest.idcampaña =;
    await this.getpage();
    this.findModalShow = false;
  };
}
